import { inject, Injectable } from "@angular/core";
import { AuthStore } from "@app/core/auth";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { map } from "rxjs";
import { TResourceSlug } from "./resource.model";
import { toSignal } from "@angular/core/rxjs-interop";

@Injectable({ providedIn: "root" })
export class RoamAclService {
  #auth = inject(AuthStore);
  #config = inject(UserConfigStore);

  readonly isGranted = (role: string | string[]) => {
    const roles = Array.isArray(role) ? role : [role];
    return this.#auth.user$.pipe(map(u => roles.some(x => x === u?.role)));
  };

  readonly hasModuleAccess = (resources: TResourceSlug | TResourceSlug[]) => {
    const resourceList = Array.isArray(resources) ? resources : [resources];
    const availableModules = this.#config.getModules();
    return availableModules.some(m => resourceList.includes(m.slug));
  };

  // Quick helper for roles
  readonly isManager = toSignal(this.isGranted("employee"));
  readonly isBoardMember = toSignal(this.isGranted("owner"));
}
